<template>
  <div>
    <b-card
      v-if="downloadUrl"
      class="text-center"
    >
      <b-spinner label="Spinning" />
    </b-card>
    <div v-if="!downloadUrl">
      <general-table
        ref="countriesTable"
        :api-url="apiUrl"
        :block-content="false"
        :columns="columns"
        :type="type"
        :need-excel="false"
      >
        <template #cell(certificate_view_url)="data">
          <span
            v-if="data.item.certificate_view_url"
            @click="handleDownload(data.item.certificate_view_url , 'stream')"
          >
            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer"
              color="black"
            />
          </span>
        </template>
        <template #cell(certificate_download_url)="data">
          <span
            v-if="data.item.certificate_download_url"
            @click="handleDownload(data.item.certificate_download_url , 'download')"
          >
            <feather-icon
              icon="DownloadIcon"
              class="cursor-pointer"
              color="black"
            />
          </span>
        </template>

      </general-table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      downloadUrl: false,
      APIURL: '/government/requests',
      type: 'page',
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'request_code', label: 'Request Code' },
        { key: 'status', label: 'Status' },
        { key: 'created_at', label: 'created At' },
        { key: 'certificate_name.ar', label: 'Certificate Name Ar' },
        { key: 'certificate_name.en', label: 'Certificate Name En' },
        { key: 'status', label: 'Status' },
        { key: 'enternal_status', label: 'Enternal Status' },
        { key: 'subcertificate_name.ar', label: 'Sub Certificate Name En' },
        { key: 'subcertificate_name.en', label: 'Sub Certificate Name Ar' },
        { key: 'certificate_view_url', label: 'Certificate view' },
        { key: 'certificate_download_url', label: 'Certificate download' },
      ],
    }
  },
  computed: {
    apiUrl() {
      let base = '/government/requests?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  mounted() {},

  methods: {
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },

    async handleDownload(url, type) {
      try {
        this.downloadUrl = true
        const response = await axios.get(url, {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })

        // Get filename from headers (Axios-specific syntax)
        const fileName = response.headers['x-filename'] || 'document'
        const blob = response.data
        const downloadUrl = window.URL.createObjectURL(blob)

        if (type === 'stream') {
          window.open(downloadUrl, '_blank')
        } else {
          const a = document.createElement('a')
          a.href = downloadUrl
          a.download = fileName // Use the filename from headers
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(downloadUrl)
          document.body.removeChild(a)
        }
      } catch (error) {
        if (error.response.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error.response.data.message}`,
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        }
      } finally {
        this.downloadUrl = false
      }
    },

  },
}
</script>
